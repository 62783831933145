import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Pediatrics() {
  const title = "Pediatrics";
  const subTitle = "小児科";
  const overview = [
    "少子化時代の到来により小児科専門医数が減少しています",
    <br />,
    "乳児検診を行う等の小児科専門医を打ち出すことで差別化をする必要があります",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "設備　1,000万円",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド、吸引器、心電計 など",
  ];
  const point = "ポイント";
  const pointText = [
    "・広さの確保、隔離室を設ける",
    <br />,
    "・子供が喜ぶような雰囲気作り",
    <br />,
    "・バリアフリーを考慮",
    <br />,
    <br />,
    "救急対応や院内感染を防ぐために隔離室を設けられるような広さの確保をする",
    <br />,
    "患者様は子供が中心となるため、消毒しやすい玩具や小物の準備が必要",
    <br />,
    "子供と一緒に母親も来院するためベビーカーの使用を考慮したバリアフリーデザインを心がける",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
